import React from "react"
import Styles from "./css/styles.module.scss"
import JSONData from "branding/site-metadata.json"

export default function Footer() {
    return (
        <footer className = {Styles.footerlinks}>

            <div className = 'footerContainer' target="_blank" style = {{background: JSONData.footerColor}}>
                <a href="/terms-of-service" target="_blank" style = {{color: JSONData.footerTextColor}}>Terms of Service</a>
                <a href="/privacy-policy" target="_blank" style = {{color: JSONData.footerTextColor}}>Privacy Policy </a>

                <a href='mailto:contactus@shopgenius.com'>contactus@shopgenius.com</a>
            </div>

        </footer>
    )
}
