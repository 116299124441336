import React from "react"
import HomepageData from "./data/offer2.json"
import Styles from "./css/offer2.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
const currDate = new Date().toLocaleDateString();

class ExtendedHomepage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: HomepageData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
      if (typeof window != "undefined") {
        window.mapParams();
      }
    });
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  render() {
    return (
      <div className = {Styles.extendedhomepage}>

      <div className = {Styles.header}>

            <div className = {Styles.headerwrapper}>

              <div className = {Styles.logodiv}>
                <p className={Styles.ftcDisclosure}>This post is sponsored by Capital One Shopping</p>
                <img className = {Styles.toplogo} src={this.state.data.logo}/>
              </div>
              {this.props.children}
            </div>
      </div>

      <div className = {Styles.allContainer}>

      <h2 className = "hc_param">Use This Shopping Hack Before You Buy Anything Else</h2>
      <p className = {Styles.date}>{currDate}</p>
      <p>
      <img src='/assets/amazon_packagerainbow_1.jpeg'/>
      </p>

      <h3>If you think you’re getting the best deal when you shop on Amazon, think again. Many stores sell the same items at a lower price (or have discount codes - more on that later). But hey, I get it. Amazon is so easy & fast - and you’re a busy person. You want the best deal, but you don’t have time to scour the internet every time you need to buy something.</h3>
      <h3>Don’t worry. <span className={Styles.blueText} data-cy="cta" onClick = {() => window.triggerInstall()}>We found the solution.</span></h3>
      <h3>Shopping insiders invented a simple hack that gets you the best deal on ALL YOUR FAVORITE STORES every time you shop. And it’s so simple you might not believe it.</h3>
      <h3>Check out this deal: $151 SAVINGS on a Kitchenaid stand mixer. And that’s just the beginning.</h3>
      <p><img src='/assets/shop_genius_mixer.jpg'/></p>

      <h3><span className={Styles.heavy}>Here’s How To Save Big:</span></h3>

<h3>All you have to do is <span className={Styles.blueText} data-cy="cta" onClick = {() => window.triggerInstall()}>add Capital One Shopping</span> to your browser! Seriously, it’s that easy. Once the app is installed you shop like normal.</h3>

<h3><span className={Styles.heavy}>So Wait, How Does It Work?</span></h3>

<h3>The <span className={Styles.blueText} data-cy="cta" onClick = {() => window.triggerInstall()}>Capital One Shopping app</span> continuously looks for coupon codes, sales, and the best deals online. If a better price is found for an item you’re about to buy, a notification will appear instantly. It shows discounts you never would have found without it and might even find another retailer selling the SAME PRODUCT YOU’RE ABOUT TO BUY AT AN INSANELY BETTER PRICE.</h3>

<h3><span className={Styles.heavy}>And the best part is, the extension is 100% free.</span> You don’t even need to give out your email address or sign up. It takes about 30 seconds to <span data-cy="cta" onClick = {() => window.triggerInstall()} className={Styles.blueText}>add it to your browser</span>, and you can uninstall whenever you want. So there’s no reason not to give it a try right now - before you buy ANYTHING else and pay more than you should.</h3>

<h3><span className={Styles.heavy}>Click the blue ADD TO CHROME button right now!</span></h3>

        <div className = {Styles.footcta}>
          <div className = {Styles.btnname}>
          <button data-cy="cta" onClick = {() => window.triggerInstall()}>ADD TO CHROME</button></div>

          <div className = {Styles.rating}>
          <div className = {Styles.ratinglabel}>6,000,000+ users, Chrome Web Store</div>

          <div className = {Styles.ratingstars}>

            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            <svg class="nc-icon glyph star " x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g><path fill="#f9c319" d="M24.897,2.99l6.521,13.211l14.577,2.119c0.82,0.119,1.148,1.127,0.554,1.706L36,30.307l2.49,14.52 c0.14,0.817-0.717,1.44-1.451,1.054l-13.038-6.854l-13.04,6.856C10.227,46.27,9.37,45.647,9.51,44.83L12,30.307L1.451,20.026 c-0.594-0.578-0.266-1.587,0.554-1.706l14.577-2.119L23.103,2.99C23.47,2.247,24.53,2.247,24.897,2.99z"></path></g></svg>
            </div>
            </div>
            <span></span>

        </div>

      </div> {/*end allContainer*/}

      <div className = {Styles.footer}>
        <div className = {Styles.foottitle}><strong>As reviewed by</strong></div>

        <div className = {Styles.footcompany}>
          <img width='100px' height='26px' src='/assets/forbes.png' onClick={() => window.open('https://www.forbes.com/advisor/banking/money-saving-apps-and-browser-extensions-for-online-shopping/')}/>
          <img width='122px' height='26px' src='/assets/fortune.png' onClick={() => window.open('https://fortune.com/2022/05/26/ways-to-protect-your-money-from-inflation/')}/>
          <img width='76px' height='30px' src='/assets/usatoday.png' onClick={() => window.open('https://www.usatoday.com/story/tech/talkingtech/2019/02/28/wikibuy-google-bing-searches-can-help-you-save-amazon-purchases/3017747002/')}/>
          <img width='117px' height='20px' src='/assets/mashable.png' onClick={() => window.open('https://mashable.com/deals/may-17-capital-one-shopping')}/>
        </div>
      </div>

    </div>



    )
  }
}
export default ExtendedHomepage
