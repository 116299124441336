import React from "react"
import Styles from "./css/styles.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
class CancelModalComponent extends React.Component {

  render() {
    return (
      <div className = {Styles.cancelmodal}>
        <div className = {Styles.cancelmodalwrapper}>

        <div className = {Styles.cancelmodalcard}>

        <div className = {Styles.canceltop}>
          <div className = {Styles.cancelheader}>Hold Up!</div>
          <div className = {Styles.cancelsubhead}>Do you actually not want automatic savings at checkout?</div>

          <div className = {Styles.cancelsubheadtwo}>We only notify you when there are likely savings.</div>
        </div>

        <div className = {Styles.cancelmiddle}>

          <div className = {Styles.cancelcopy}>Join Millions of customers who found</div>

          <div className = {Styles.cancelcallout}>$160 Million <br/> in savings last year</div>
        </div>

        <div className = {Styles.cancelbutton}>
          <button data-cy="cta" onClick = {() => window.triggerInstall()}>Yes, Get Coupons</button>
          <button className = {Styles.nobutton}>No, Don't Save</button>
        </div>

        <div className = {Styles.footcompany}>
            <img width='100px' height='26px' src='/assets/forbes.png' onClick={() => window.open('https://www.forbes.com/advisor/banking/money-saving-apps-and-browser-extensions-for-online-shopping/')}/>
            <img width='122px' height='26px' src='/assets/fortune.png' onClick={() => window.open('https://fortune.com/2022/05/26/ways-to-protect-your-money-from-inflation/')}/>
            <img width='76px' height='30px' src='/assets/usatoday.png' onClick={() => window.open('https://www.usatoday.com/story/tech/talkingtech/2019/02/28/wikibuy-google-bing-searches-can-help-you-save-amazon-purchases/3017747002/')}/>
        </div>
</div>
        </div>
      </div>



    )
  }
}
export default CancelModalComponent
